import path from 'path';
import { graphql } from 'gatsby';
import PagePost from '../models/PagePost';
import PropTypes from 'prop-types';
import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { privacy as pageMeta } from '../data/page_meta.yml';
import { siteMeta } from '../data/site_meta.yml';
import PageWrapper from '../components/DefaultPageWrapper';
import {
    Link,
    SEO,
    TableWrapper,
} from '@brandedholdings/react-components';

class Privacy extends React.Component {
    getChildContext() {
        return { siteMeta };
    }

    render() {
        const page = this.props;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);

        const pageImages = {};
        page.data.pageImages.edges.forEach(({ node }) => pageImages[path.basename(node.absolutePath).split('.')[0]] = node);

        return (
            <PageWrapper>
                <SEO post={post} />
                <BackgroundImage
                    className="background__gatsby background background__graphic"
                    fluid={pageImages.hero.image.fluid}
                >
                    <div className="layout">
                        <div className="layout-content center">
                            <h1>Privacy Policy</h1>
                        </div>
                    </div>
                </BackgroundImage>
                <div className="background background__white">
                    <div className="layout">
                        <div className="layout-content">

                            <h3>Effective: September 8, 2020</h3>
                            <p>This Privacy Policy (the “Policy”) applies to the {siteMeta.nameShort} website (the “Website”). It tells users (“you”) of the Website and the services it provides about the information collected by the Website and how the Website uses and shares this information.</p>

                            <h4>The Information {siteMeta.nameShort} Collects</h4>
                            <p>{siteMeta.nameShort} collects both information that may identify a particular individual (“<strong>Personally Identifiable Information</strong>”) and anonymous information that cannot be associated with a specific individual (“<strong>non-Personally Identifiable Information</strong>”) from or about users of its websites.</p>

                            <h6>Personally Identifiable Information</h6>
                            <p>{siteMeta.nameShort} collects Personally Identifiable Information when you provide it through your use of the Website, such as by completing one or more stages of an online form (a “<strong>Form</strong>”) requesting a loan offer from a lender participating in {siteMeta.nameShort}’s lender network (a “<strong>Lender Partner</strong>”) or by sending questions to {siteMeta.nameShort} via the Website. The types of Personally Identifiable Information {siteMeta.nameShort} collects include: name, physical address, telephone number, social security number, email address, date of birth, driver’s license number and state, information about home ownership, citizenship status, employment information, active military information, amount and frequency of pay checks, and information about your banking account.</p>

                            <h6>Non-Personally Identifiable Information</h6>
                            <p>The types of non-Personally Identifiable Information that {siteMeta.nameShort} collects (either directly, through the use of “<strong>cookies</strong>,” or through the use of a third party tracking system) include: the IP address of the computer making the request; your browser type and language; access times; information about areas of the site visited by users of the site; information about the links that users may select to visit on the site. IP addresses are not usually associated with your personal computer, but with a portal through which you accessed the Internet. IP addresses are not linked to Personally Identifiable Information. A “<strong>cookie</strong>” is a piece of information a website sends to your computer while you are viewing a website to remember who you are without personally identifying you.</p>

                            <p>{siteMeta.nameShort} may share this non-Personally Identifiable Information with its suppliers and other third parties for the purpose of website statistical and trend analysis. This information is not linked to any Personally Identifiable Information.</p>

                            <h4>Information Collected by Third Parties</h4>
                            <p>{siteMeta.nameShort} may allow third parties, including advertising partners and personal loan lenders, to display advertisements on the Website. These companies may use tracking technologies, including cookies, to collect information about users of the Website who view or interact with these advertisements. {siteMeta.nameShort} does not provide Personally Identifiable Information to these third parties.</p>

                            <h4>How We Use Personally Identifiable Information</h4>
                            <p>{siteMeta.nameShort} uses the Personally Identifiable Information you provide through the Website in order to deliver the products or services you have requested; provide you with customer support; communicate with you by email, postal mail, telephone, and/or mobile devices (including through short messaging services, or “<strong>SMS</strong>”) about products or services that may be of interest to you either from {siteMeta.nameShort}, Lender Partners, other third party lenders, {siteMeta.nameShort}’s marketing partners, or other third parties; display advertising tailored to your interests or background; perform data and statistical analysis on use of the website; enforce {siteMeta.nameShort}’s terms and conditions, and perform any other functions otherwise described to you at the time {siteMeta.nameShort} collects the information.</p>

                            <p>{siteMeta.nameShort} may review and use your financial information and your social security number to connect you with Lender Partners or other third party lenders with whom {siteMeta.nameShort} has a business relationship. {siteMeta.nameShort} may also review your financial information and social security number to verify your identify and protect against possible fraudulent transactions.</p>

                            <h4>Sharing Your Personally Identifiable Information</h4>
                            <p>{siteMeta.nameShort} may share your Personally Identifiable Information (including, without limitation, your financial account information and social security number) with Lender Partners and other third party lenders with whom {siteMeta.nameShort} has a business relationship. These third party lenders may use your Personally Identifiable Information to offer you a personal loan or to communicate with you about other business or marketing offers. In addition, {siteMeta.nameShort} may share your Personally Identifiable Information (including your financial account information and social security number) with unaffiliated third party marketers and list managers with whom {siteMeta.nameShort} has a business relationship.</p>

                            <p>Any third parties with whom {siteMeta.nameShort} shares Personally Identifiable Information may have their own policies which describe how they use and disclose your information. Those policies will govern the use, handling, and disclosure of your information once {siteMeta.nameShort} has transferred or shared it with those third parties as described in this Policy or the {siteMeta.nameShort} <Link to="/terms.html">Terms and Conditions</Link>.</p>

                            <p>{siteMeta.nameShort} will disclose your Personally Identifiable Information in response to a subpoena or similar investigative demand, a court order, or a request for cooperation from a law enforcement agency or other government agency; to establish or exercise our legal rights; to defend against legal claims; or as otherwise required by law. {siteMeta.nameShort} will disclose your Personally Identifiable Information when {siteMeta.nameShort} believes disclosure is necessary to investigate, prevent, or take action regarding illegal activity, suspected fraud, or other wrongdoing; to protect and defend the rights, property, or safety of {siteMeta.nameShort}’s parent company, its employees, its website users, or others; or to enforce the <Link to="/terms.html">Terms and Conditions</Link> or other agreements or policies.</p>

                            <p>In addition, {siteMeta.nameShort} may transfer Personally Identifiable Information to an entity acquiring all or substantially all of its parent company’s stock or assets.</p>

                            <h4>Links to Third Party Websites</h4>
                            <p>{siteMeta.nameShort} may provide links or automatically redirect you to third party websites that do not operate under this Policy. For example, if you click on an advertisement presented on our site, you may be taken to a website that {siteMeta.nameShort} does not operate or control. You should review the privacy statements and terms of use of all third party websites you access. These third party websites may independently solicit and collect information from you. On occasion, those third party websites may provide {siteMeta.nameShort} with information about your use of those sites.</p>

                            <h4>Changes</h4>
                            <p>The Website’s policies, content, information, promotions, disclosures, disclaimers and features may be revised, modified, updated, and/or supplemented at any time and without prior notice at {siteMeta.nameShort}’s sole and absolute discretion. Changes to this Policy will be posted on the Website and {siteMeta.nameShort} will revise the “Effective” date at the top of this policy. You should review this Policy each time you access the Website. If {siteMeta.nameShort} makes any material changes in the way it collects, uses, and/or shares personal information that may impact you, {siteMeta.nameShort} will notify you by posting notice of the changes on this website.</p>

                            <h4>Security</h4>
                            <p>We take reasonable and appropriate security precautions to secure the personal information that you choose to provide against the loss, misuse, alteration, or unauthorized access of such information. Unfortunately, no transmission of data over the Internet is guaranteed to be completely secure. While we strive to protect your personal information, we cannot ensure or warrant the security or confidentiality of any personal information you transmit to us.</p>

                            <h4>Interest Based Advertising</h4>
                            <p>We may partner with ad networks and other ad serving providers (“Advertising Providers”) who serve ads on behalf of us and others on non-affiliated sites. Some of those ads may be personalized, meaning that they are intended to be relevant to you based on information Advertising Providers collect about your visits to this site and elsewhere over time. Our Advertising Providers use cookies to personalize advertisements that you may see on other websites.</p>

                            <p>Because an industry-standard Do-Not-Track protocol has not yet been established, we will not change your browsing experience based on any “Do Not Track” signals that may be sent by certain browsers or selected by you.</p>

                            <p>However, you can visit www.cookiecentral.com and http://www.aboutads.info to learn more about this type of advertising and how to opt-out of this form of advertising by companies participating in the Digital Advertising Alliance (“DAA”) self-regulatory program. Note that electing to opt-out will not stop advertising from appearing in your browser or requests. It may make the ads you see less relevant to your interests. In addition, note that if you use a different browser or erase cookies from your browser, you may need to renew your opt-out choice. As each browser is different, please check the help section of your browser for instructions on how to remove cookies and learn about other privacy and security settings that may be available.</p>

                            <h4>Children’s Privacy</h4>

                            <p>The Website is not intended for use by anyone under the age of 18 or the age of majority in your jurisdiction. {siteMeta.nameShort} does not knowingly collect Personally Identifiable Information from children under the age of 18.</p>

                            <h4>Security</h4>

                            <p>{siteMeta.nameShort} has put in place physical, procedural, and technological security measures to help prevent unauthorized access to and improper use of the information {siteMeta.nameShort} collects online. For example, only authorized employees and authorized third parties are permitted to access Personally Identifiable Information, and they may do so only for permitted business functions. No website or Internet network can be completely secure. Although {siteMeta.nameShort} takes steps to secure your information, {siteMeta.nameShort} does not guarantee, and you should not expect, that your personal information, searches, or other communications will always remain secure.</p>

                            <h4>Text Message Policy</h4>
                            <p>By providing your cell phone number, you have provided {siteMeta.sms.name} with consent to send you recurring text messages in conjunction with the services you have requested.</p>
                            <ul className="list">
                                <li>You may opt-out at any time by texting the word STOP. An opt-out confirmation message will be sent back to you.</li>
                                <li>For help, text the word HELP.</li>
                                <li>Message frequency varies. Message and data rates may apply.</li>
                                <li>To request support, Text HELP.</li>
                            </ul>
                            
                            
                            <p>MMS - If your phone doesn’t support MMS, you’ll receive SMS instead.</p>
                            <p>This program is supported by all U.S. carriers. {siteMeta.sms.name}, carriers (including but not limited to T-Mobile) and any service provider used by {siteMeta.sms.name} to send messages are not liable for delayed or undeliverable messages.</p>
                            


                            <h4>Accessing Your Personally Identifiable Information</h4>
                            <p>If you would like to review and/or request changes to the Personally Identifiable Information that {siteMeta.nameShort} has collected about you through your use of the Website, please contact {siteMeta.nameShort} by writing or sending email to the addresses below.</p>

                            <p>Privacy Rights of California Residents: Companies that collect Personally Identifiable Information from California residents and disclose such information to third parties (including affiliated entities) for marketing purposes must, in response to a request by a consumer, either (1) provide a list detailing the categories of information shared and the entities to which such information was provided, or (2) provide a mechanism by which a consumer may opt out of having his or her information shared with third parties. {siteMeta.nameShort} has elected the second option, and you may opt out by completing one or more forms on our <Link to="/unsubscribe/">Opt Out</Link> page.</p>

                            <p>California residents are also entitled to know that they may file grievances and complaints with California Department of Consumer Affairs, 400 R Street, Suite 1080, Sacramento, CA 95814; or by phone at 916-445-1254 or 800-952-5210; or by email to dca@dca.ca.gov.</p>


                            <h4>Contact Us</h4>
                            <p>If you have questions, comments, or concerns regarding our Privacy Statement, please email us or click to <Link to="/contact.html">Contact Us</Link>.</p>

                            <p>
                                <strong>
                                    {siteMeta.address.street}
                                    <br />
                                    {siteMeta.address.street2}
                                    <br />
                                    {siteMeta.address.city}, {siteMeta.address.state} {siteMeta.address.zip}
                                </strong>
                            </p>

                            <TableWrapper>
                                <table className="table text-left">
                                    <thead>
                                        <tr>
                                            <th>FACTS</th>
                                            <th>WHAT DO WE DO WITH YOUR PERSONAL INFORMATION?</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                Why?
                                        </td>
                                            <td>
                                                Companies choose how they share your personal information. Federal law gives consumers the right to limit some but not all sharing. Federal law also requires us to tell you how we collect, share, and protect your personal information. Please read this notice carefully to understand what we do.
                                        </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                What?
                                        </td>
                                            <td>
                                                <p>The types of personal information we collect and share depend on the product or service you have with us. This information can include, but is not limited to:</p>
                                                <ol className="list">
                                                    <li>Social Security number</li>
                                                    <li>Credit history</li>
                                                    <li>Employment and income information</li>
                                                    <li>Banking account information</li>
                                                    <li>Contact information</li>
                                                </ol>
                                                <p>When you are no longer our customer, we continue to share your information as described in this notice.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                How
                                        </td>
                                            <td>
                                                Companies need to share customers' personal information to run their everyday business. In the section below, we list the reasons companies can share their customers' personal information; the reasons we choose to share; and whether you can limit this sharing.
                                        </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TableWrapper>
                        </div>
                    </div>
                </div>
            </PageWrapper>
        );
    }
};

Privacy.childContextTypes = {
    siteMeta: PropTypes.object
};

export default Privacy;

export const query = graphql`
    {
        pageImages: allFile(filter: {absolutePath: {regex: "^static/assets/images/covers/"}}) {
            edges {
                node {
                    id
                    absolutePath
                    image: childImageSharp {
                        fluid(
                            quality: 90, 
                            maxWidth: 2000,
                            traceSVG: { background: "#0ba7e2", color: "#0ba7e2" }
                        ) {
                            # base64
                            tracedSVG
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }
    }
`;


